import {
	addDays as addDaysFns,
	differenceInDays as differenceInDaysFns,
	format as formatFns,
	getYear as getYearFns,
	isValid as isValidFns,
	parse as parseFns,
	parseISO,
	startOfMonth as startOfMonthFns,
	subDays as subDaysFns,
	lastDayOfMonth,
} from 'date-fns';
import { DateFormat } from '~models/Util';

export class DateUtil {
	private readonly dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
	private readonly timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
	private readonly dateTimeNoTimezoneRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}T\d{2}:\d{2}:\d{2}$/;
	public as = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
	private readonly dayRegex = /^(0[1-9]|[12][0-9]|3[01])$/;
	private readonly monthRegex = /^(0[1-9]|1[0-2])$/;
	public readonly REG_EXP_DATE: RegExp =
		/^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/gm;
	private readonly ISO8601 = /^(\d{4}-\d{2}-\d{2})(T(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;
	public readonly dateTimeHhMm = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

	public MONTHS = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];
	constructor() {}

	get getYear(): string {
		return `${this.getYearFromDate(this.getCurrentDate())}`;
	}

	public formatDate(date: Date | string | null | undefined, format: DateFormat = DateFormat.DATE): any {
		if (date) {
			return formatFns(this.strToDate(date), format);
		} else {
			return '';
		}
	}

	strToDate(date: string | Date): Date {
		if (date instanceof Date) {
			return date;
		}

		const dateFormat = this.getFormat(date);

		let parsedDate = dateFormat == DateFormat.ISO ? parseISO(date) : this.parse(date, dateFormat);
		if (!this.isValid(parsedDate)) {
			throw new Error('Invalid date');
		}
		return parsedDate;
	}

	isValid(date: string | Date | number): boolean {
		return isValidFns(date);
	}

	getFormat(dateStr: string): DateFormat {
		if (this.ISO8601.test(dateStr)) {
			return DateFormat.ISO;
		} else if (this.dateRegex.test(dateStr)) {
			return DateFormat.DATE;
		} else if (this.timeRegex.test(dateStr)) {
			return DateFormat.TIME;
		} else if (this.dateTimeNoTimezoneRegex.test(dateStr)) {
			return DateFormat.DATETIME_NO_TIMEZONE;
		} else if (this.dayRegex.test(dateStr)) {
			return DateFormat.DAY;
		} else if (this.monthRegex.test(dateStr)) {
			return DateFormat.MONTH;
		} else if (this.dateTimeHhMm.test(dateStr)) {
			return DateFormat.ISODATETIME_HH_MM;
		} else {
			throw new Error('Unknown format');
		}
	}

	parse(date: string, format: DateFormat = DateFormat.DATE): Date {
		return parseFns(date, format, new Date());
	}

	addDay(date: Date | string, days: number): Date {
		const newDate = this.strToDate(date);
		return addDaysFns(newDate, days);
	}

	subtractDay(date: Date, days: number): Date {
		const newDate = this.strToDate(date);
		return subDaysFns(newDate, days);
	}

	getLastDayFromMonth(input: string | Date, targetFormat: DateFormat = DateFormat.DATE): string {
		return this.formatDate(lastDayOfMonth(this.strToDate(input)), targetFormat);
	}

	getLastDayOfCurrentMonth(targetFormat: DateFormat = DateFormat.DATE): string {
		return this.formatDate(lastDayOfMonth(new Date()), targetFormat);
	}

	getFirstDayOfCurrentMonth(targetFormat: DateFormat = DateFormat.DATE): string {
		return this.formatDate(startOfMonthFns(new Date()), targetFormat);
	}

	getCurrentDate(format: DateFormat = DateFormat.DATE) {
		return this.formatDate(new Date(), format);
	}

	getYearFromDate(input: string | Date = new Date()): number {
		const dateObj = this.strToDate(input);
		return getYearFns(dateObj);
	}

	getBirthDay(input: string | Date) {
		const dia = this.formatDate(input, DateFormat.DAY);
		const mes = this.MONTHS[this.formatDate(input, DateFormat.MONTH) - 1];
		const formatoDeseado = `${dia} de ${mes}`;
		return formatoDeseado;
	}

	public diffDays(later: string | Date, earlier: string | Date): number {
		const laterDate = this.strToDate(later);
		const earlierDate = this.strToDate(earlier);
		return differenceInDaysFns(laterDate, earlierDate);
	}

	public addDays(date: string | Date, days: number): Date {
		const newDate = this.strToDate(date);
		return addDaysFns(newDate, days);
	}

	validatorFecha(fch: string): {
		valid: boolean;
		errfch: string;
	} | null {
		if (!fch) {
			return null;
		}
		const valid = this.isValid(this.parse(fch));
		let errfch = '';
		if (!valid) {
			errfch = 'Fecha no valida';
		}
		return { valid, errfch };
	}
}
